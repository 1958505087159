/* global google */
import React, { useEffect } from "react"
import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react"
import HTMLReactParser from "html-react-parser"
import { useIntl } from "gatsby-plugin-react-intl"
import _ from "lodash"

const MapContainer = props => {
  const google = window.google
  const data = props.data
  const center = props.center
  const moment = require("moment")
  const intl = useIntl()

  const googleMapStyles = [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#e9e9e9",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#dedede",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#333333",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#f2f2f2",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ]

  const isSSR = typeof window === "undefined"
  const onMarkerClick = itemId => {
    if (!isSSR) {
      const elemID = document.getElementById(itemId)

      const url = window.location.origin + window.location.pathname
      const nextURL = url + "#" + itemId

      // This will create a new entry in the browser's history, reloading afterwards
      window.location.href = nextURL

      // This will replace the current entry in the browser's history, reloading afterwards
      window.location.assign(nextURL)

      // This will replace the current entry in the browser's history, reloading afterwards
      window.location.replace(nextURL)

      //console.log(window.location)
      elemID.click()

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }, 1000)
    }
  }

  return (
    <div className="map-container">
      <Map
        google={google}
        className={"map"}
        zoom={props.zoom}
        initialCenter={props.center}
        styles={googleMapStyles}
        fullscreenControl={false}
        zoomControl={false}
        mapTypeControl={false}
        center={{ lat: props.selectedItem.lat, lng: props.selectedItem.lng }}
      >
        {data.map(item => (
          <Marker
            key={item.id}
            title={item.name}
            name={item.name}
            icon={{
              url: item.relationships?.field_marker?.relationships?.field_image
                .localFile.childImageSharp.fluid.src,
              scaledSize: new google.maps.Size(40, 40), // scaled size
              origin: new google.maps.Point(0, 0), // origin
              anchor: new google.maps.Point(20, 40), // anchor
              shouldFocus: false,
            }}
            position={{ lat: item.lat, lng: item.lng }}
            onClick={e =>
              onMarkerClick(_.upperFirst(_.camelCase(item.title)), e)
            }
          />
        ))}
        <InfoWindow
          visible={props.windowOpen}
          shouldFocus={false}
          position={{
            lat: props.selectedItem.lat,
            lng: props.selectedItem.lng,
          }}
        >
          <div className="marker-card">
            <div
              className="marker-image"
              style={{
                backgroundImage:
                  "url(" +
                  props.selectedItem?.relationships?.field_featured_media
                    ?.relationships.media?.relationships.field_image?.localFile
                    .childImageSharp.fluid.srcWebp +
                  ")",
              }}
            ></div>
            <div className="marker-info">
              <h3 className="font-style-3">
                {props.selectedItem.title && HTMLReactParser(props.selectedItem.title.toString().replace(/&/g, "<em className='speCarac'>&</em>"))}
              </h3>
              <div className="description">
                {props.selectedItem.relationships?.field_category
                  ? props.selectedItem.relationships.field_category.name
                  : ""}{" "}
                <br />{" "}
                {props.selectedItem.todayOpeningHours?.comment
                  ? props.selectedItem.todayOpeningHours.comment
                  : ""}
                {props.selectedItem.todayOpeningHours &&
                  props.selectedItem.todayOpeningHours?.endhours !== null &&
                  "Open until" +
                    " " +
                    moment(
                      props.selectedItem.todayOpeningHours?.endhours,
                      "hh:mm"
                    ).format("LT")}
              </div>
              <div className="features">
                <span
                  className={
                    props.selectedItem.field_dine_in ? "opt-ok" : "opt-nok"
                  }
                >
                  {intl.formatMessage({ id: "annuaire.dine_in" })}
                </span>
                <span
                  className={
                    props.selectedItem.field_take_away ? "opt-ok" : "opt-nok"
                  }
                >
                  {intl.formatMessage({ id: "annuaire.take_away" })}
                </span>
                <span
                  className={
                    props.selectedItem.field_delivery ? "opt-ok" : "opt-nok"
                  }
                >
                  {intl.formatMessage({ id: "annuaire.delivery" })}
                </span>
              </div>
              <div className="contact">
                {props.selectedItem.field_telephone && (
                  <a
                    target="_blank"
                    href={"tel:" + props.selectedItem.field_telephone}
                    rel="noopener"
                  >
                    <i className="icon-phone-outline"></i>
                  </a>
                )}
                {props.selectedItem.field_email && (
                  <a
                    target="_blank"
                    href={"mailto:" + props.selectedItem.field_email}
                    rel="noopener"
                  >
                    <i className="icon-mail-outline"></i>
                  </a>
                )}
                {props.selectedItem.field_website && (
                  <a
                    target="_blank"
                    href={props.selectedItem.field_website.url}
                    rel="noopener"
                  >
                    <i className="icon-web"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCyaBv560iHUxqRW-KFFIg65AHmz-pHeYo",
})(MapContainer)
