import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "../components/common/heading"
import Layout from "../components/layout"
import MapContainer from "../components/map/map"
import { useIntl } from "gatsby-plugin-react-intl"
import CustomScroll from "react-custom-scroll"
import { setSeoData } from "../services/seo.service"
import Seo from "../components/seo"
import Media from "../components/common/media"
import HTMLReactParser from "html-react-parser"
import _ from "lodash"

const Annuaire = () => {
  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "page.annuaire.seo.title" }),
    field_seo: {
      description: intl.formatMessage({
        id: "page.annuaire.seo.meta.description",
      }),
      title: intl.formatMessage({ id: "page.annuaire.seo.title" }),
      og_description: intl.formatMessage({
        id: "page.annuaire.seo.ogTags.description",
      }),
      og_image: intl.formatMessage({ id: "page.annuaire.seo.ogTags.image" }),
      og_locale: intl.locale,
      og_title: intl.formatMessage({ id: "page.annuaire.seo.ogTags.title" }),
      og_type: intl.formatMessage({ id: "page.annuaire.seo.ogTags.type" }),
    },
  })

  const data = useStaticQuery(graphql`
    query AnnuaireQuery {
      en: allNodeAnnuaireItem(
        filter: { langcode: { eq: "en" }, title: { ne: "Dummy" } }
        sort: { fields: title, order: ASC }
      ) {
        ...getAnnuaireItems
      }
      fr: allNodeAnnuaireItem(
        filter: { langcode: { eq: "fr" }, title: { ne: "Dummy" } }
        sort: { fields: title, order: ASC }
      ) {
        ...getAnnuaireItems
      }
    }
  `)
  const isSSR = typeof window === "undefined"

  let annuaires
  // Get annuaire items by locale
  switch (intl.locale) {
    case "fr":
      if (data.fr && data.fr.nodes) {
        annuaires = data.fr.nodes
      }
      break
    default:
      if (data.en && data) {
        annuaires = data.en.nodes
      }
  }

  var OpenLocationCode = require("open-location-code").OpenLocationCode
  var openLocationCode = new OpenLocationCode()

  let longPlusCode
  let decoded
  const moment = require("moment")

  const handleLeftCol = () => {
    const btn = document.querySelector(".toggleMenu")
    const parentBlk = document.querySelector(".layoutAnnuaire")
    btn.classList.toggle("hide")
    parentBlk.classList.toggle("wide")
    parentBlk.classList.toggle("wideMob")
  }

  const mobileSelected = () => {
    const parentBlk = document.querySelector(".layoutAnnuaire")
    parentBlk.classList.toggle("wideMob")
  }

  const d = new Date()
  let day = d.getDay()

  if (annuaires.length > 0 && !isSSR) {
    const elemID = document.getElementById("___gatsby")
    elemID.classList.add("pageAnnuaire")
  }
  annuaires.forEach(annuaire => {
    //Convert long plus code to lat & long
    longPlusCode = annuaire["plus_code_long"]
    decoded = openLocationCode.decode(longPlusCode)

    //Get today opening hours for info window details
    const actualOpeningHours = annuaire.field_opening_hours.filter(list => {
      return list.day === day
    })

    annuaire["lat"] = decoded.latitudeCenter
    annuaire["lng"] = decoded.longitudeCenter
    annuaire["todayOpeningHours"] = actualOpeningHours[0]

    return annuaires
  })

  const [searchInput, setSearchInput] = useState("")
  const [filteredResults, setFilteredResults] = useState([])
  const [windowOpen, setWindowOpen] = useState(true)

  // Search bar
  const searchItems = searchValue => {
    setSearchInput(searchValue)

    if (searchInput !== "") {
      const filteredData = annuaires.filter(annuaire => {
        return Object.values(annuaire)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(annuaires)
    }
    //Close info window while searching (Prevent lose focus bug)
    setWindowOpen(false)
  }

  // Left col list
  const AnnuaireList = props => {
    const items = props.items
    return (
      <>
        {items.map((item, index) => {
          return (
            <div
              onClick={
                e => {
                  props.onClick(e, item);
                  mobileSelected()
                }
              }
              className={
                "annuaire-item" + (selectedItem.id === item.id ? " active" : "")
              }
              id={_.upperFirst(_.camelCase(item.title))}
              key={index}
            >
              <div className="annuaire-featured-img">
                {item.relationships.field_featured_media && (
                  <Media
                    media={
                      item.relationships.field_featured_media.relationships
                        .media
                    }
                  />
                )}
              </div>
              <Heading
                title={HTMLReactParser(
                  item.title
                    .toString()
                    .replace(/&/g, "<em className='speCarac'>&</em>")
                )}
                fontStyle={3}
                description={
                  item.relationships?.field_category
                    ? item.relationships.field_category.name
                    : ""
                }
              />
              <div className="annuaire-item-details">
                {item.field_annuaire_location && (
                  <div className="location">
                    <i className="icon-location"></i>
                    <p>
                      {item.field_annuaire_location.address_line1 +
                        ", " +
                        item.field_annuaire_location.address_line2}{" "}
                      <br />{" "}
                      {item.field_annuaire_location.locality +
                        " " +
                        item.field_annuaire_location.country_code +
                        ", " +
                        item.field_annuaire_location.postal_code}
                    </p>
                  </div>
                )}
                {item.field_opening_hours.length > 0 && (
                  <>
                    <div className="hours">
                      <i className="icon-clock"></i>
                      <p>{intl.formatMessage({ id: "opening_hours" })}</p>
                    </div>
                    <table>
                      {item.field_opening_hours.map(list => {
                        return (
                          <tr>
                            <td>
                              {intl.formatMessage({ id: "day." + list.day })}
                            </td>
                            {list.comment ? (
                              <td>{list.comment} </td>
                            ) : (
                              <td>
                                {(list.starthours.toString().length === 3
                                  ? moment(
                                      "0" + list.starthours.toString(),
                                      "hh:mm"
                                    ).format("LT")
                                  : moment(list.starthours, "hh:mm").format(
                                      "LT"
                                    )) +
                                  " - " +
                                  (list.endhours.toString().length === 3
                                    ? moment(
                                        "0" + list.endhours.toString(),
                                        "hh:mm"
                                      ).format("LT")
                                    : moment(list.endhours, "hh:mm").format(
                                        "LT"
                                      ))}{" "}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                    </table>
                  </>
                )}
                <div className="social">
                  {item.field_facebook && (
                    <div className="icon">
                      <a
                        href={item.field_facebook.url}
                        target="_blank"
                        rel="noopener"
                      >
                        <i className="icon-fb-2"></i>
                      </a>
                    </div>
                  )}
                  {item.field_instagram && (
                    <div className="icon">
                      <a
                        href={item.field_instagram.url}
                        target="_blank"
                        rel="noopener"
                      >
                        <i className="icon-insta-2"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  // If search is empty get all data
  const annuaireData = searchInput.length > 1 ? filteredResults : annuaires

  const [selectedItem, setSelectedItem] = useState({})

  const showInfo = (e, selectedItem) => {
    setSelectedItem(selectedItem)
    setWindowOpen(true)
  }

  // Get Mobile
  const window1 = typeof window !== "undefined" ? window : ""

  const [width, setWidth] = useState(window1.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window1.innerWidth)
  }
  useEffect(() => {
    window1.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window1.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  let isMobile = width <= 768

  //Recenter on selected item
  const center = { lat: -20.500294816388493, lng: 57.42010670736034 }
  return (
    <Layout>
      <Seo data={seoData} />
      <Container fluid className={isMobile ? "layoutAnnuaire wideMob" : "layoutAnnuaire"}>
        <Row>
          <Col md={4} className="list-col">
            <CustomScroll
              heightRelativeToParent={isMobile ? "calc(100vh - 100px)" : "calc(100vh - 230px)"}
            >
              <div className="annuaire-searchbox">
                <button className={isMobile ? "toggleMenu hide" : "toggleMenu"} onClick={handleLeftCol}></button>
                <input
                  type="text"
                  aria-label="Search"
                  placeholder="Chercher par catégorie ou mot-clé"
                  autoFocus="autoFocus"
                  onChange={e => searchItems(e.target.value)}
                />
                <div className="icon">
                  <i className="icon-search"></i>
                </div>
              </div>
              <AnnuaireList
                items={annuaireData}
                onClick={showInfo.bind(this)}
                selectedItem={selectedItem}
              />
            </CustomScroll>
          </Col>
          <Col md={12} className="map-col">
            <MapContainer
              center={center}
              zoom={isMobile ? 14 : 15}
              data={annuaireData}
              selectedItem={selectedItem}
              windowOpen={windowOpen}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Annuaire
